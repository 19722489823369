<template>
  <div>
    <v-col v-if="cartItems && cartItems.length">
      <v-card
        elevation="0"
        class="mb-4"
        color="white"
        tile
        v-for="(product, index) in cartItems.slice().reverse()"
        v-bind:key="index"
      >
        <v-row class="mx-1">
         
          <v-col cols="5" sm="4" md="4" lg="4" class="pt-3">
            <div v-if="product.customAttributes.isPersonalize == 1">
              <div v-if="builderSession(product)">
                <div
                  v-for="(item, itemIndex) in product.thumbnailUrl"
                  :key="itemIndex"
                  class="mb-2"
                >
                  <ImageZoom
                    :title="product.name"
                    :altText="product.name"
                    :scale="0.8"
                    :img-normal="
                      item ? item : product.customAttributes.thumbnail
                    "
                    :productDetails="
                      item ? item : product.customAttributes.thumbnail
                    "
                    :productIndex="0"
                    @imagesDialog="showImagesDialog"
                  ></ImageZoom>
                </div>
              </div>
              <div v-else>
                <ImageZoom
                  :title="product.name"
                  :scale="0.8"
                  :altText="product.name"
                  :img-normal="
                    imageBasePathUrl + product.customAttributes.image
                  "
                  :productDetails="
                    imageBasePathUrl + product.customAttributes.image
                  "
                  :productIndex="0"
                  @imagesDialog="showImagesDialog"
                ></ImageZoom>
              </div>
            </div>
            <div v-else>
              <ImageZoom
                :title="product.name"
                :scale="0.8"
                :altText="product.name"
                :img-normal="imageBasePathUrl + product.customAttributes.image"
                :productDetails="
                  imageBasePathUrl + product.customAttributes.image
                "
                :productIndex="0"
                @imagesDialog="showImagesDialog"
              ></ImageZoom>
            </div>
          </v-col>

          <v-col cols="7" v-show="!cartProductPage" class="pb-0 my-0">
            <v-row class="ma-0">
              <v-col class="px-0 pt-2 pb-2 ma-0">
                <p
                  class="fontsize-14 text-black float-left cursor-pointer"
                  @click="
                    goToProductDetails(
                      product.customAttributes,
                      product.name,
                      product.productType
                    )
                  "
                >
                  {{ product.name }}
                </p>
              </v-col>
            </v-row>
            <v-expansion-panels
              class="pa-0 mt-n3 active-panel-edited"
              v-show="
                product.productType == 'configurable' ||
                product.productType == 'giftcard'
              "
            >
              <v-expansion-panel
                elevation="0"
                class="pa-0 ma-0"
                v-if="product.productOption"
              >
                <v-expansion-panel-title class="pa-0 ma-0"
                  >See Details</v-expansion-panel-title
                >
                <v-expansion-panel-text
                  v-if="product.productType == 'giftcard'"
                  class="cartproductlist-expansion"
                >
                  <p
                    class="font-weight-bold fontsize-14 text-black float-left cursor-pointer mb-1"
                  >
                    Gift Card Sender
                  </p>
                  <br />
                  <p
                    v-if="product.productOption.infoBuyRequest"
                    class="fontsize-14 text-black float-left cursor-pointer"
                  >
                    {{
                      product.productOption.infoBuyRequest.giftcardSenderName
                    }}
                    <br />
                    {{
                      product.productOption.infoBuyRequest.giftcardSenderEmail
                    }}
                  </p>
                  <br />
                  <p
                    class="font-weight-bold fontsize-14 text-black float-left cursor-pointer mb-1"
                  >
                    Gift Card Recipient
                  </p>
                  <br />
                  <p
                    v-if="product.productOption.infoBuyRequest"
                    class="fontsize-14 text-black float-left cursor-pointer"
                  >
                    {{
                      product.productOption.infoBuyRequest.giftcardRecipientName
                    }}
                    <br />
                    {{
                      product.productOption.infoBuyRequest
                        .giftcardRecipientEmail
                    }}
                  </p>
                </v-expansion-panel-text>
                <v-expansion-panel-text
                  v-else
                  class="cartproductlist-expansion"
                >
                  <p
                    class="font-weight-bold fontsize-14 text-black text-left cursor-pointer mb-1"
                  >
                    Option
                  </p>
                  <p
                    v-if="product.productOption.attributesInfo.length > 0"
                    class="fontsize-14 text-black cursor-pointer"
                  >
                    {{ product.productOption.attributesInfo[0].value }}
                  </p>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <p class="fontsize-14">${{ priceFormatter(product.price) }}</p>

            <div v-if="pageName() != 'CheckoutPage'">
              <v-row
                v-if="product.customAttributes.isPersonalize == 1"
                align="center"
                class="mt-0"
              >
                <v-col cols="auto" class="pr-1 pt-0">
                  <p class="fontsize-14 mb-0 pb-7">Qty.</p>
                </v-col>
                <v-col class="pl-0 pt-0">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-select
                      :items="
                        qtyPriceOptions(
                          product.customAttributes.qtyPrice,
                          product.qty,
                          index
                        )
                      "
                      class="fontsize-16 line-height-21 ls-n009 font-weight-400 changeOnHover cartproductlist-select-button seTextBoxWidth"
                      variant="outlined"
                      density="compact"
                      v-bind="props"
                      attach
                      :class="isHovering ? 'text-primary' : 'text-black'"
                      v-model="selectedQty[index]"
                      @update:modelValue="
                        updateToCart(
                          product.name,
                          product.sku,
                          product.itemId,
                          index,
                          product.price,
                          product.personalizeDetails,
                          product.productOption,
                          product.customAttributes.letter,
                          product.productType,
                          product.customAttributes.isPersonalize,
                          product.customAttributes.builderVersion,
                          product.ccid,
                          product.parentSku,
                          product,
                          $event
                        )
                      "
                      :model-value="selectedQty[index]"
                      ref="selectedCartQty4"
                      :append-inner-icon="mdiPlus"
                      item-value="qty"
                      item-title="qty"
                      item-children="qty"
                    ></v-select>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row align="center" v-else class="my-0 pb-0">
                <v-col cols="auto" class="pr-1 py-0">
                  <p class="fontsize-14 mb-0 pb-7">Qty.</p>
                </v-col>
                <v-col class="pl-0 pb-0 mb-0">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-select
                      :items="
                        numberOptions(
                          product.customAttributes.baMinSalesQty,
                          product.customAttributes.baQtyIncrements,
                          product.qty,
                          index
                        )
                      "
                      class="word-break fontsize-14 line-height-21 ls-n009 font-weight-400 cartproductlist-select-button select-change-icon seTextBoxWidth"
                      :class="isHovering ? 'text-primary' : 'text-black'"
                      single-line
                      v-model="selectedQty[index]"
                      :model-value="selectedQty[index]"
                      @update:modelValue="
                        updateToCart(
                          product.name,
                          product.sku,
                          product.itemId,
                          index,
                          product.price,
                          product.personalizeDetails,
                          product.productOption,
                          product.customAttributes.letter,
                          product.productType,
                          product.customAttributes.isPersonalize,
                          product.customAttributes.builderVersion,
                          product.ccid,
                          product.parentSku,
                          product,
                          $event
                        )
                      "
                      :menu-props="{
                        auto: true,
                        overflowY: false,
                        zIndex: 9999,
                        backgroundColor: `#fff`,
                      }"
                      ref="selectedCartQty2"
                      :append-inner-icon="mdiPlus"
                      v-bind="props"
                      density="compact"
                      variant="outlined"
                    ></v-select>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row class="pt-5 pb-10">
                <v-col cols="auto" class="py-0 pr-0">
                  <p class="fontsize-14 mb-1">Qty.</p>
                </v-col>
                <v-col cols="auto" class="py-0 pl-2">
                  <p class="fontsize-14 text-black">{{ product.qty }}</p>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <!---------------------------cart page - img description -------------------------------------------->
          <v-col
            cols="7"
            sm="8"
            md="8"
            lg="8"
            class="py-0 mt-0"
            v-show="cartProductPage"
          >
            <v-row class="my-0">
              <v-col cols="12" sm="6" md="5" lg="6">
                <v-row>
                  <p
                    class="mt-2 mb-3 fontsize-14 text-black float-left cursor-pointer"
                    @click="
                      goToProductDetails(
                        product.customAttributes,
                        product.name,
                        product.productType
                      )
                    "
                  >
                    {{ product.name }}
                  </p>
                </v-row>
                <v-row>
                  <p
                    class="fontsize-14 mb-3 text-uppercase"
                    v-show="cartProductPage"
                  >
                    Sku {{ product.sku }}
                  </p>
                </v-row>
                <v-row>
                  <p class="fontsize-14 mb-0">
                    ${{ priceFormatter(product.price) }}
                  </p>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" md="7" lg="6">
                <v-row>
                  <v-col cols="10" sm="8" md="9" lg="7" class="pb-0">
                    <v-row
                      v-if="product.customAttributes.isPersonalize == 1"
                      align="center"
                      class="mt-0"
                    >
                      <div cols="12" class="pl-0 py-0">
                        <p class="fontsize-14 mb-0 pb-2">Qty.</p>

                        <v-hover v-slot="{ isHovering, props }">
                          <v-select
                            :items="
                              qtyPriceOptions(
                                product.customAttributes.qtyPrice,
                                product.qty,
                                index
                              )
                            "
                            class="fontsize-10 line-height-21 ls-n009 font-weight-400 changeOnHover cartproductlist-select-button"
                            variant="outlined"
                            density="compact"
                            v-bind="props"
                            attach
                            :class="isHovering ? 'text-primary' : 'text-black'"
                            v-model="selectedQty[index]"
                            :model-value="selectedQty[index]"
                            @update:modelValue="
                              updateToCart(
                                product.name,
                                product.sku,
                                product.itemId,
                                index,
                                product.price,
                                product.personalizeDetails,
                                product.productOption,
                                product.customAttributes.letter,
                                product.productType,
                                product.customAttributes.isPersonalize,
                                product.customAttributes.builderVersion,
                                product.ccid,
                                product.parentSku,
                                product,
                                $event
                              )
                            "
                            ref="selectedCartQty4"
                            background-color="transparent"
                            :append-inner-icon="mdiPlus"
                            item-value="qty"
                            item-title="qty"
                            item-children="qty"
                          ></v-select>
                        </v-hover>
                      </div>
                    </v-row>
                    <!-- product qty -->
                    <v-row align="center" class="mt-0" v-else>
                      <!-- mobile view qty -->
                      <div class="d-sm-none">
                        <p class="fontsize-14 mt-1 mr-1 mb-0 pb-2">Qty.</p>

                        <v-hover v-slot:default="{ isHovering, props }">
                          <v-select
                            :items="
                              numberOptions(
                                product.customAttributes.baMinSalesQty,
                                product.customAttributes.baQtyIncrements,
                                product.qty,
                                index
                              )
                            "
                            class="fontsize-10 line-height-21 ls-n009 font-weight-400 changeOnHover cartproductlist-select-button"
                            variant="outlined"
                            density="compact"
                            v-bind="props"
                            attach
                            :class="isHovering ? 'text-primary' : 'text-black'"
                            v-model="selectedQty[index]"
                            :model-value="selectedQty[index]"
                            @update:modelValue="
                              updateToCart(
                                product.name,
                                product.sku,
                                product.itemId,
                                index,
                                product.price,
                                product.personalizeDetails,
                                product.productOption,
                                product.customAttributes.letter,
                                product.productType,
                                product.customAttributes.isPersonalize,
                                product.customAttributes.builderVersion,
                                product.ccid,
                                product.parentSku,
                                product,
                                $event
                              )
                            "
                            ref="selectedCartQty4"
                            background-color="transparent"
                            :append-inner-icon="mdiPlus"
                          ></v-select>
                        </v-hover>
                        <p class="fontsize-14 pb-4">
                          ${{ priceFormatter(product.rowTotal) }}
                        </p>
                      </div>

                      <!-- desktop view -->

                      <div class="myFlex">
                        <p
                          class="fontsize-14 mt-1 mr-1 mb-0 pb-16 d-none d-sm-block"
                        >
                          Qty.
                        </p>
                        <v-hover v-slot:default="{ isHovering, props }">
                          <v-select
                            :items="
                              numberOptions(
                                product.customAttributes.baMinSalesQty,
                                product.customAttributes.baQtyIncrements,
                                product.qty,
                                index
                              )
                            "
                            class="fontsize-16 line-height-21 ls-n009 font-weight-400 changeOnHover cartproductlist-select-button d-none d-sm-block"
                            variant="outlined"
                            density="compact"
                            v-bind="props"
                            attach
                            :class="isHovering ? 'text-primary' : 'text-black'"
                            v-model="selectedQty[index]"
                            :model-value="selectedQty[index]"
                            @update:modelValue="
                              updateToCart(
                                product.name,
                                product.sku,
                                product.itemId,
                                index,
                                product.price,
                                product.personalizeDetails,
                                product.productOption,
                                product.customAttributes.letter,
                                product.productType,
                                product.customAttributes.isPersonalize,
                                product.customAttributes.builderVersion,
                                product.ccid,
                                product.parentSku,
                                product,
                                $event
                              )
                            "
                            ref="selectedCartQty4"
                            background-color="transparent"
                            :append-inner-icon="mdiPlus"
                          ></v-select>
                        </v-hover>
                      </div>
                    </v-row>
                  </v-col>
                  <!-- product price -->
                  <v-col
                    cols="12"
                    sm="4"
                    md="3"
                    lg="5"
                    class="pb-0 d-none d-sm-block"
                  >
                    <p class="fontsize-14 float-right mb-12">
                      ${{ priceFormatter(product.rowTotal) }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <!-- stock and Qty ---->
              <div v-if="product.stockDetails.manageStock">
                <div v-if="!product.stockDetails.isInStock">
                  <v-alert class="word-break" type="error" tile
                    >This product is out of stock.</v-alert
                  >
                </div>
                <div v-else>
                  <v-alert
                    class="word-break"
                    type="error"
                    tile
                    v-if="
                      product.stockDetails.qty < product.qty &&
                      product.stockDetails.backorders == 0
                    "
                    >The requested qty is not available.</v-alert
                  >
                  <v-alert
                    class="word-break"
                    type="warning"
                    tile
                    v-if="
                      product.stockDetails.qty < product.qty &&
                      product.stockDetails.backorders == 2
                    "
                    >We don't have as many quantity as you requested, but we'll
                    back order the remaining {{ product.qty }}.</v-alert
                  >
                </div>
              </div>
            </v-row>
          </v-col>
          <!------------------------------------------------------------------------------------->
          <v-row class="ma-0 pb-0 pt-0" v-show="checkOut">
            <v-col align="right">
              <a
                class="fontsize-14 text-black text-uppercase cartproductlist-action-link cursor-pointer"
                @click.stop="
                  editProduct(
                    product.name,
                    product.sku,
                    product.itemId,
                    index,
                    product.customAttributes.isPersonalize,
                    product.customAttributes.builderVersion,
                    product.ccid,
                    product.productType,
                    product.parentSku,
                    product
                  )
                "
                >Edit</a
              >
              <span class="px-1">|</span>
              <a
                class="fontsize-14 text-black text-uppercase cartproductlist-action-link cursor-pointer"
                @click.stop="
                  remove(
                    product.name,
                    product.sku,
                    product.itemId,
                    product.parentSku,
                    product
                  )
                "
                >Remove</a
              >
              <span v-if="builderSession(product)">
                <span class="px-1">|</span>
                <a
                  class="fontsize-14 text-black text-uppercase cartproductlist-action-link cursor-pointer"
                  @click.stop="saveForLater(product)"
                  >Save for later
                </a>
              </span>
            </v-col>
            <div class="myDivider mb-4"></div>
          </v-row>
        </v-row>
        <v-row> </v-row>
      </v-card>
      <v-col class="text-center mb-12" v-if="isGateModulePage">
        <router-link
          class="text-decoration-none cartproductlist-link border-left-none"
          to
          @click="gateViewEditCart()"
          >View and edit cart</router-link
        >
      </v-col>
      <v-col
        v-else
        class="text-center mb-12"
        v-show="pageName() !== 'CartProducts' && pageName() !== 'CheckoutPage'"
      >
        <v-hover v-slot="{ isHovering, props }">
          <router-link
            v-bind="props"
            :class="isHovering ? 'content-link-hover-color' : 'text-primary'"
            class="text-decoration-none cartproductlist-link border-left-none"
            :to="{ name: 'CartProducts' }"
            @click="closeDrawer()"
            >View and edit cart</router-link
          >
        </v-hover>
      </v-col>
    </v-col>

    <ConfirmationDialog
      :key="confirmationDialog"
      :confirmationDialog="confirmationDialog"
      :confirmationDetails="confirmation"
      @actionRemove="removeProduct"
      v-if="confirmationDialog"
    ></ConfirmationDialog>

    <v-dialog v-model="openImageDialog" content-class="prod-view-dialog">
      <v-card flat class="">
        <v-card-title class="d-flex justify-space-between py-2">
          <p class="h5 mb-0">Product Images</p>
          <v-btn flat @click="closeImageDialog()" icon>
            <!-- <v-icon small color="primary">mdi-window-close</v-icon> -->
            <v-icon :icon="mdiWindowClose" small color="primary" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-4">
          <v-container class="pa-0" v-if="imageSrcs && imageSrcs.length">
            <v-row no-gutters justify="center" class="">
              <v-col lg="8" md="8" cols="12" sm="8" class="pa-0">
                <v-card tile class="py-3" flat>
                  <v-img
                    v-bind:src="sliderImgPath"
                    class="ma-1"
                    contain
                    :max-height="imageMaxHeight"
                  ></v-img>
                  <!-- height="auto"
                    :max-height="imageMaxHeight"
                    width="500" -->
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col
                v-for="productImageSrc in imageSrcs"
                v-bind:key="productImageSrc"
                cols="2"
              >
                <v-hover v-slot:default="{ isHovering, props }">
                  <v-card
                    max-width="100"
                    class="mx-1"
                    outlined
                    @click="setImgToSlider(productImageSrc)"
                    :color="isHovering ? 'primary' : 'white'"
                    v-bind:class="{ primary: productImageSrc == sliderImgPath }"
                    tile
                    v-bind="props"
                  >
                    <v-img v-bind:src="productImageSrc" max-width="100"></v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Sign In Form -->
    <v-dialog v-model="signInForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign In</p>
          <v-btn
            @click="closeSignInForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <!-- <v-icon>mdi-window-close</v-icon> -->
            <v-icon :icon="mdiWindowClose" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signInMessage">{{
            signInMessage
          }}</v-alert>
          <v-form ref="loginForm" v-model="validLogin">
            <v-col class="pt-3 pb-1">
              <v-text-field
                v-model="emailLogin"
                type="email"
                label="Email Address"
                ref="email"
                name="email"
                variant="outlined"
                density="comfortable"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col class="pt-1 pb-0">
              <v-text-field
                v-model="passwordLogin"
                label="Password"
                ref="password"
                type="password"
                name="password"
                variant="outlined"
                density="comfortable"
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signIn()"
                :disabled="inProgress || signInProgress"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress || signInProgress"
                ></v-progress-circular>
                &nbsp;SIGN IN
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignUpDialog()"
                >Sign Up</span
              >
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right">
              
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sign Up -->
    <v-dialog v-model="signUpForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign Up</p>
          <v-btn
            @click="closeSignUpForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <!-- <v-icon>mdi-window-close</v-icon> -->
            <v-icon :icon="mdiWindowClose" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signUpMessage">{{
            signUpMessage
          }}</v-alert>
          <v-form ref="signUpForm" id="signup-form" @submit.prevent="signUp">
            <v-text-field
              class="pt-2"
              label="First Name"
              type="text"
              ref="firstName"
              name="firstName"
              variant="outlined"
              density="comfortable"
              v-model="firstname"
              :rules="firstNameRules"
            ></v-text-field>
            <v-text-field
              class="pt-2"
              label="Last Name"
              type="text"
              ref="lastName"
              name="lastName"
              variant="outlined"
              density="comfortable"
              v-model="lastname"
              :rules="lastNameRules"
            ></v-text-field>

            <v-text-field
              class="pt-2"
              label="Email Id"
              type="email"
              ref="email"
              name="email"
              id="userEmail"
              variant="outlined"
              density="comfortable"
              v-model="email"
              :rules="signUpEmailRules"
            ></v-text-field>

            <v-text-field
              class="pt-2 mt-0"
              type="password"
              label="Password"
              ref="password"
              name="password"
              variant="outlined"
              density="comfortable"
              v-model="password"
              :rules="commonValidationRules.passwordRule"
            ></v-text-field>
            <v-checkbox
              v-model="newsletter"
              label="Subscribe to our newsletter"
              hide-details="true"
              color="indigo"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signUp(product)"
                :disabled="inProgress || signInProgress"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress || signInProgress"
                ></v-progress-circular>
                &nbsp;SIGN UP
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignInDialog()"
                >Sign In</span
              >
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right">
             
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
 
import { VueperSlides, VueperSlide } from "vueperslides";
const personlizeUrl = import.meta.env.VITE_PERSONALIZE_URL;
const retailorId = import.meta.env.VITE_RETAILOR_ID;
import { getPartPricByStructure } from "@/services/getSessionItemsPricerObject.service.js";
import { pricingEngine, pricerFieldsIdx, pricerFiles } from "crane-pricer";
import {
  getLable,
  customAttributeValue,
  priceFormatter,
  checkPromotion,
  googleAanalyticsEventRemoveCart,
  commonValidationRules,
  googleAanalyticsEventSaveForLatter,
  isoStringToNormalDate,
} from "@/services/util.service";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { doFetchProductTags } from "@/services/product.service";
import { removeCookieByName } from "@/services/auth";
import ImageZoom from "@/components/common/ImageZoom.vue";
import { createHelpers } from "vuex-map-fields";
import { mdiWindowClose, mdiPlus } from "@mdi/js";

const { mapFields: mapFieldsAuth } = createHelpers({
  getterType: "auth/getField",
  mutationType: "auth/updateField",
});
const { mapFields: registerInfo } = createHelpers({
  getterType: "registration/getField",
  mutationType: "registration/updateField",
});

export default {
  name: "CartProductList",
  props: ["cartProductPage", "checkOut"],
  components: { ConfirmationDialog, VueperSlides, VueperSlide, ImageZoom },
  data: () => ({
    mdiWindowClose,
    mdiPlus,

    sliderImgPath: "",
    priceFormatter: priceFormatter,
    isActive: true,
    selectedQty: [],
    selectedQtyPersonalize: "",
    retailorId: "08c43bbf-3735-4621-85f9-d71dc2cfb41b",
    itemNumber: "193C",
    quantity: "25",
    savFromKey: "Ub07GxNhS3yyoDfO",
    ccId: "766a5cef-6b51-4ab4-9f25-6446c4c8baeb",
    confirmationDialog: false,
    confirmation: {
      confirmationMessage: "",
    },
    blue: "primary",
    white: "white",
    dialog: false,
    productId: "",
    currentProduct: null,
    signInProgress: false,
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
    removeProdcutName: "",
    thumbnailAbsent: false,
    productDetails: "",
    imageSrcs: [],
    openImageDialog: false,
    signInForm: false,
    passwordFields: false,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
    validLogin: false,
    lazy: false,
    signUpForm: false,
    valid: true,
    commonValidationRules: commonValidationRules,
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length >= 3) || "First Name must be at least 3 characters",
    ],
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) => (v && v.length >= 3) || "Last Name must be at least 3 characters",
    ],
     
    signUpEmailRules: [
      (v) => !!v || "Email Id is required",
      (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
    ],
  }),
  computed: {
    ...mapFieldsAuth({
      oneLogin: "one",
      emailLogin: "one.email",
      passwordLogin: "one.password",
      errorMessage: "errorMessage",
      inProgress: "inProgress",
      signInMessage: "signInMessage",
    }),
    ...registerInfo([
      "one",
      "one.firstname",
      "one.lastname",
      "one.newsletter",
      "one.email",
      "one.password",
      "signUpMessage",
    ]),
    cartItems() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    
    productAttributes() {
      return this.$store.state.productList.attributeList.items;
    },
    isGateModulePage: function () {
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");
      if (isGateModulePage) return true;

      return false;
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    fetchPriceInProgress: function () {
      return this.$store.state.productOne.fetchPriceInProgress;
    },
    perSlides: function () {
       
      return 1; //this.$vuetify.display.mdAndUp ? 4 : 4;
    },
    slideHeight: function () {
      return this.$vuetify.display.xs ? "400px" : "400px";
    },
    showArrows: function () {
      if (this.$vuetify.display.smAndDown) return false;
      else return true;
    },
    imageMaxHeight: function () {
      if (this.$vuetify.display.xlAndUp) return "1600px";
      else if (this.$vuetify.display.lgAndUp) return "1000px";
      else if (this.$vuetify.display.mdAndUp) return "600px";
      else if (this.$vuetify.display.xs) return "400px";
      else return "400px";
    },
    routeName: function () {
      return this.$route.name;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
  },
  watch: {
    cartItems: {
      immdediate: true,
      async handler() {
        this.calledListrakCartList();
        window.pricerFieldsIdx = pricerFieldsIdx;
        if (this.cartItems && this.cartItems.length) {
          if (this.userAuth) {
            this.$store.commit("userCartProduct/cartProductsProgress", true);
          } else {
            this.$store.commit("cartProduct/cartProductsProgress", true);
          }
          let skuList = [];
          for (let p = 0; p < this.cartItems.length; p++) {
            let product = this.cartItems[p];
            if (product && product.options) {
              let resp = this.builderSession(product);
              if (resp) skuList.push(product.sku);
            }
          }
          await this.fetchPricerBundle(skuList);
          if (this.userAuth) {
            this.$store.commit("userCartProduct/cartProductsProgress", false);
          } else {
            this.$store.commit("cartProduct/cartProductsProgress", false);
          }
        }
      },
    },
    signInForm(flag) {
       
    },
  },
  methods: {
    async fetchPricerBundle(skuList) {
      if (skuList.length) {
        let currentDateInIso = new Date().toISOString();
        let bundleResp = await this.$store.dispatch(
          "pricerBundleOne/fetchPriceBundle",
          { skuList: skuList, availableAt: currentDateInIso }
        );
        if (bundleResp) {
          window.pricer = {
            ...pricerFiles,
            ...bundleResp,
          };
        }
      }
      console.log("Pricer resp ", window.pricer);
    },
    // Save for later code
    async saveForLater(product) {
      try {
        let builderSessionOne = null;
        this.currentProduct = product;
        this.cartProductsProgress = true;
        if (this.userAuth) {
          if (
            product &&
            product.options &&
            product.options.options &&
            product.options.options.length > 0
          ) {
            let optionsList = product.options.options;
            builderSessionOne = optionsList.find(
              (x) => x.label == "BuilderSessionId"
            );

            if (builderSessionOne) {
              let sessionId = builderSessionOne.optionValue;
              if (sessionId) {
                await this.$store.dispatch("sessionOne/updateOne", {
                  sessionId: sessionId,
                  property: {
                    orderState: null,
                    quoteData: null,
                    state: "saved",
                  },
                });

                await this.$store.dispatch(
                  "userCartProduct/removeCartProduct",
                  {
                    productId: product.itemId,
                  }
                );
                
                googleAanalyticsEventSaveForLatter(product, "saved_for_later");

                this.snackbar = {
                  message: "Product Added Successfully in Saved For Later",
                  color: "success",
                  show: true,
                  timeout: 3000,
                };
                this.$emit("actionClose", this.snackbar);
              }
            }
          }
        } else {
          this.signInForm = true;
        }
      } catch (error) {
        console.log("Error occured : " + error);
      }
    },

    async signIn() {
      try {
        this.cartProductsProgress = true;
        this.signInProgress = true;
        const { valid } = await this.$refs.loginForm.validate();
        if (valid) {
          this.$store.dispatch("auth/changeRedirectFlag", false);
          let status = await this.$store.dispatch("auth/login");
          if (status) {
            this.$store.dispatch("auth/changeRedirectFlag", true);
            await this.$store.dispatch("accountOne/basicInfo");
            let builderSessionOne = null;
            if (
              this.currentProduct &&
              this.currentProduct.options &&
              this.currentProduct.options.options &&
              this.currentProduct.options.options.length > 0
            ) {
              let optionsList = this.currentProduct.options.options;
              builderSessionOne = optionsList.find(
                (x) => x.label == "BuilderSessionId"
              );

              if (builderSessionOne) {
                await this.$store.dispatch(
                  "userCartProduct/cartProductList",
                  builderSessionOne.optionValue
                );
                if (this.basicInfo) {
                  await this.$store.dispatch("sessionOne/updateOne", {
                    sessionId: builderSessionOne.optionValue,
                    property: {
                      orderState: null,
                      quoteData: null,
                      state: "saved",
                      userData: {
                        customer_id: this.basicInfo.id,
                        customer_name: this.basicInfo.firstname,
                      },
                    },
                  });
                }

                this.signInForm = false;
              }
            }
          }
          this.signInProgress = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async signUp() {
     
      const { valid } = await this.$refs.signUpForm.validate();
      if (valid) {
        try {
          this.signInProgress = true;
          this.$store.dispatch("auth/changeRedirectFlag", false);
          let status = await this.$store.dispatch("registration/signUp");
          if (status) {
            this.$store.dispatch("auth/changeRedirectFlag", false);
            this.$store.dispatch("accountOne/basicInfo");
            let builderSessionOne = null;
            if (
              this.currentProduct &&
              this.currentProduct.options &&
              this.currentProduct.options.options &&
              this.currentProduct.options.options.length > 0
            ) {
              let optionsList = this.currentProduct.options.options;
              builderSessionOne = optionsList.find(
                (x) => x.label == "BuilderSessionId"
              );

              if (builderSessionOne) {
                await this.$store.dispatch(
                  "userCartProduct/cartProductList",
                  builderSessionOne.optionValue
                );
                if (this.basicInfo) {
                  await this.$store.dispatch("sessionOne/updateOne", {
                    sessionId: builderSessionOne.optionValue,
                    property: {
                      orderState: null,
                      quoteData: null,
                      state: "saved",
                      userData: {
                        customer_id: this.basicInfo.id,
                        customer_name: this.basicInfo.firstname,
                      },
                    },
                  });
                }
                this.signUpForm = false;
                this.signInForm = false;
              }
            }
          }
          this.signInProgress = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
    closeSignInForm() {
      this.signInForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    closeSignUpForm() {
      this.signUpForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    openSignInDialog() {
      this.signInForm = true;
      this.signUpForm = false;
    },
    openSignUpDialog() {
      this.signInForm = false;
      this.signUpForm = true;
    },
    goToForgetPassword() {
      window.open(`/gate/forgot-password`, "_self");
    },
    // Save for later code end

    builderSession(product) {
      let builderSessionOne = null;
      if (
        product &&
        product.options &&
        product.options.options &&
        product.options.options.length > 0
      ) {
        let optionsList = product.options.options;
        builderSessionOne = optionsList.find(
          (x) => x.label == "BuilderSessionId"
        );

        if (builderSessionOne) {
          return true;
        }
      }
      return false;
    },
    closeImageDialog() {
      this.openImageDialog = false;
    },
    setImgToSlider(imgSrc) {
      this.sliderImgPath = imgSrc;
    },
    showImagesDialog(data, isPersonalize) {
      if (this.routeName == "CartProducts") {
        this.imageSrcs = [];
        if (isPersonalize) {
          let parsedData = JSON.parse(data.personalizeDetails);
          for (let i = 0; i < parsedData.length; i++) {
            this.imageSrcs.push(
              parsedData[i].thumbnailUrl
                ? parsedData[i].thumbnailUrl
                : data.customAttributes.thumbnail
            );
          }
        } else {
          this.imageSrcs.push(data);
        }

        if (this.imageSrcs.length) {
          this.openImageDialog = true;
          this.sliderImgPath = this.imageSrcs[0];
        }
      }
    },
    calledListrakCartList() {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/setListrakCartList");
      } else {
        if (this.guestQuoteId) {
          this.$store.dispatch("cartProduct/setListrakCartList");
        }
      }
    },
    changeCheckoutFlag(flag) {
      this.$emit("stockFlag", flag);
    },
    checkThumbnails(personalizeDetails) {
      if (personalizeDetails) {
        let imgCount = 0;
        let i;
        let personalizeDetailsArray = JSON.parse(personalizeDetails);
        for (i = 0; i < personalizeDetailsArray.length; i++) {
          if (personalizeDetailsArray[i].thumbnailUrl) imgCount++;
        }
        if (i == imgCount) return true;
        return false;
      }
      return false;
    },
    getAttribute(customAttributes, attributeCode) {
      if (this.productAttributes) {
        let newValue = this.productAttributes.filter((elements) => {
          return elements.attributeCode === attributeCode;
        });
        if (newValue.length > 0) {
          let labelValue = newValue[0].options.filter((elementNew) => {
            return elementNew.value === customAttributes;
          });
          if (labelValue.length > 0) {
            return labelValue[0].label;
          }
        }
      }
    },
    pageName() {
      return this.$route.name;
    },
    qtyPriceOptions(list, qty, index) {
      this.selectedQty[index] = Number(qty).toString();
      return JSON.parse(list);
    },
    numberOptions: function (baMinSalesQty, baQtyIncrements, qty, index) {
      if (baMinSalesQty && baQtyIncrements) {
        let minSalesQty = baMinSalesQty;
        let qtyIncrements = baQtyIncrements;
        minSalesQty = Number(minSalesQty) ? Number(minSalesQty) : 1;
        qtyIncrements = Number(qtyIncrements) ? Number(qtyIncrements) : 1;

        let nums = [];
        nums[0] = minSalesQty;
        if (qty) {
          this.selectedQty[index] = Number(qty);
        }
        for (let i = 1; i <= 98; i++) {
          minSalesQty = minSalesQty + qtyIncrements;
          nums[i] = minSalesQty;
        }
        if (nums.length > 0) return nums;
        else return "";

        
      } else {
        return "";
      }
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    getCustomAttributeLable(customAttributes, attributeCode) {
      return getLable(customAttributes, attributeCode);
    },
    gateViewEditCart() {
      this.closeDrawer();
      window.open(`/cart-products`, "_self");
    },
    closeDrawer() {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
    },
    goToProductDetails(customAttributes, productName, productType) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");

      if (isGateModulePage) {
        let productPath = customAttributes.urlKey
          ? customAttributes.urlKey
          : customAttributeValue(customAttributes, "url_key");
        window.open(`/${productPath}`, "_self");
      } else {
        if (productType == "configurable") {
           
          this.$router
            .push({
              name: productName,
            })
            .catch((error) => {
              error;
            });
        } else {
          this.$router
            .push({
              name: productName,
            })
            .catch((error) => {
              error;
            });
        }
      }
    },
    remove(productName, sku, itemId, parentSku, product) {
      this.productDetails = product;
      console.log(sku, parentSku);
      this.productId = itemId;
      this.removeProdcutName = productName;
      (this.confirmationDialog = true),
        (this.confirmation = {
          confirmationMessage:
            "Are you sure you would like to remove this item from the shopping cart?",
        });
    },
    editProduct(
      productName,
      sku,
      itemId,
      index,
      isPersonalize,
      builderVersion,
      ccid,
      productType,
      parentSku,
      product
    ) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
      if (isPersonalize == "1") {
        if (builderVersion == "2.0") {
          let builderSessionOne = null;
          if (
            product &&
            product.options &&
            product.options.options &&
            product.options.options.length > 0
          ) {
            let optionsList = product.options.options;
            builderSessionOne = optionsList.find(
              (x) => x.label == "BuilderSessionId"
            );
          }
          if (builderSessionOne) {
            window.open(
              `${personlizeUrl}/builder/session/resume/${builderSessionOne.value}`,
              "_self"
            );
          }
        } else {
          this.$router
            .push({
              name: "CraneConnection",
              params: {
                retailorId: retailorId,
                quantity: this.selectedQty[index],
                itemNumber: sku,
                ccid: ccid,
                savItemId: itemId,
              },
            })
            .catch((error) => {
              error;
            });
        }
      } else {
        let pagePath = this.$route.path;
        let customAttributes = product.customAttributes;
        let isGateModulePage = pagePath.includes("/gate/");
        if (isGateModulePage) {
          let productPath = customAttributes.urlKey
            ? customAttributes.urlKey
            : customAttributeValue(customAttributes, "url_key");
          window.open(
            `/${productPath}?qty=${this.selectedQty[index]}&itemId=${itemId}`,
            "_self"
          );
        } else {
          this.$emit("actionEditMessage");
          if (productType == "configurable") {
            this.$router
              .push({
                name: productName,
                query: {
                  qty: this.selectedQty[index],
                  itemId: itemId,
                },
              })
              .catch((error) => {
                error;
              });
          } else if (productType == "giftcard") {
            this.$router
              .push({
                name: productName,
                query: {
                  qty: this.selectedQty[index],
                  itemId: itemId,
                },
              })
              .catch((error) => {
                error;
              });
          } else {
            this.$router
              .push({
                name: productName,
                query: {
                  qty: this.selectedQty[index],
                  itemId: itemId,
                },
              })
              .catch((error) => {
                error;
              });
          }
        }
      }
    },

    async updateToCart(
      productName,
      sku,
      itemId,
      index,
      price,
      personalizeDetails,
      optionId,
      optionValue,
      productType,
      isPersonalize,
      builderVersion,
      ccid,
      parentSku,
      product,
      event
    ) {
      let vm = this;
      if (this.pageName() != "CheckoutPage") {
        if (this.userAuth) {
          if (isPersonalize == "1") {
            if (builderVersion == "2.0" && itemId && price && product) {
              const quantity = this.selectedQty[index];
              let updatedPriceData = await this.getPersonalizedPrice(
                product,
                quantity,
                sku
              );
              await this.updateToCartItem(
                null,
                sku,
                quantity,
                itemId,
                this.$route.name,
                updatedPriceData.subTotal,
                updatedPriceData.personalizeDetails,
                true,
                builderVersion,
                updatedPriceData.sessionId
              );
            } else {
              try {
                const quantity = vm.selectedQty[index];
                let response = await this.$store.dispatch(
                  "userCartProduct/updateToCConectionCart",
                  {
                    sku: sku,
                    qty: quantity,
                    ccid: ccid,
                  }
                );
                if (response) {
                  await this.updateToCartItem(
                    ccid,
                    sku,
                    quantity,
                    itemId,
                    this.$route.name,
                    null,
                    null,
                    true
                  );
                }
              } catch (error) {
                this.snackbar = {
                  message: error.message,
                  color: "error",
                  show: true,
                  timeout: 3000,
                };
                setTimeout(() => {
                  this.$emit("actionClose", this.snackbar);
                }, 3000);
              }
            }
          } else if (
            productType == "configurable" &&
            itemId &&
            optionId &&
            optionValue
          ) {
            let quantity = vm.selectedQty[index];
            try {
              await this.$store.dispatch(
                "userCartProduct/updateToConfiguredCart",
                {
                  ccid: null,
                  sku: parentSku,
                  qty: quantity,
                  itemId: itemId,
                  routeName: this.$route.name,
                  optionId: optionId.attributesInfo[0].optionId,
                  optionValue: optionValue,
                }
              );
              this.snackbar = {
                message: "Product Updated Successfully",
                color: "success",
                show: true,
                timeout: 3000,
              };
            } catch (error) {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
            setTimeout(() => {
              this.$emit("actionClose", this.snackbar);
            }, 3000);
            if (vm.$route.name == productName && vm.$route.query.qty)
              vm.$router.push({
                name: productName,
                query: { qty: quantity, itemId: itemId },
              });
          } else {
            let quantity = vm.selectedQty[index];
            await this.updateToCartItem(
              null,
              sku,
              quantity,
              itemId,
              this.$route.name,
              null,
              null,
              true
            );
            if (vm.$route.name == productName && vm.$route.query.qty)
              vm.$router.push({
                name: productName,
                query: { qty: quantity, itemId: itemId },
              });
          }
        } else {
          if (isPersonalize == "1") {
            if (builderVersion == "2.0" && itemId && price && product) {
              const quantity = this.selectedQty[index];
              let updatedPriceData = await this.getPersonalizedPrice(
                product,
                quantity,
                sku
              );
              await this.updateToCartItem(
                null,
                sku,
                quantity,
                itemId,
                this.$route.name,
                updatedPriceData.subTotal,
                updatedPriceData.personalizeDetails,
                false,
                builderVersion,
                updatedPriceData.sessionId
              );
            } else {
              try {
                const quantity = this.selectedQty[index];
                let response = await this.$store.dispatch(
                  "cartProduct/updateToCConectionCart",
                  {
                    sku: sku,
                    qty: quantity,
                    ccid: ccid,
                  }
                );
                if (response) {
                  await this.updateToCartItem(
                    ccid,
                    sku,
                    quantity,
                    itemId,
                    this.$route.name,
                    null,
                    null,
                    false
                  );
                }
              } catch (err) {
                this.snackbar = {
                  message: err.message,
                  color: "error",
                  show: true,
                  timeout: 3000,
                };
                setTimeout(() => {
                  this.$emit("actionClose", this.snackbar);
                }, 3000);
              }
            }
          } else if (
            productType == "configurable" &&
            itemId &&
            optionId &&
            optionValue
          ) {
            let quantity = vm.selectedQty[index];
            try {
              await this.$store.dispatch("cartProduct/updateToConfiguredCart", {
                ccid: null,
                sku: parentSku,
                qty: quantity,
                itemId: itemId,
                routeName: this.$route.name,
                optionId: optionId.attributesInfo[0].optionId,
                optionValue: optionId.attributesInfo[0].optionValue,
              });
              this.snackbar = {
                message: "Product Updated Successfully",
                color: "success",
                show: true,
                timeout: 3000,
              };
            } catch (error) {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
            setTimeout(() => {
              this.$emit("actionClose", this.snackbar);
            }, 3000);
            if (vm.$route.name == productName && vm.$route.query.qty)
              vm.$router.push({
                name: productName,
                query: { qty: quantity, itemId: itemId },
              });
          } else {
            let quantity = this.selectedQty[index];
            await this.updateToCartItem(
              null,
              sku,
              quantity,
              itemId,
              this.$route.name,
              null,
              null,
              false
            );
            if (this.$route.name == productName && this.$route.query.qty)
              this.$router.replace({
                name: productName,
                query: { qty: quantity, itemId: itemId },
              });
          }
        }
      }
    },
    async removeProduct(action) {
    
      try {
        if (action.submit) {
          this.confirmationDialog = false;
          if (this.userAuth) {
            try {
              let builderSessionOne = null;
              if (
                this.productDetails &&
                this.productDetails.options &&
                this.productDetails.options.options &&
                this.productDetails.options.options.length > 0
              ) {
                let optionsList = this.productDetails.options.options;
                builderSessionOne = optionsList.find(
                  (x) => x.label == "BuilderSessionId"
                );

                if (builderSessionOne) {
                  let sessionId = builderSessionOne.optionValue;
                  if (sessionId) {
                    await this.$store.dispatch("sessionOne/updateOne", {
                      sessionId: sessionId,
                      property: {
                        orderState: null,
                        quoteData: null,
                      },
                    });
                  }
                }
              }
            } catch (error) {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
            try {
              await this.$store.dispatch("userCartProduct/removeCartProduct", {
                productId: this.productId,
              });
              googleAanalyticsEventRemoveCart(
                "remove_from_cart",
                this.productDetails
              );
              removeCookieByName(this.productId);
              this.snackbar = {
                message: "Product Removed Successfully",
                color: "red",
                show: true,
                timeout: 3000,
              };
            } catch (error) {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
            this.$emit("actionClose", this.snackbar);
          } else {
            try {
              let builderSessionOne = null;
              if (
                this.productDetails &&
                this.productDetails.options &&
                this.productDetails.options.options &&
                this.productDetails.options.options.length > 0
              ) {
                let optionsList = this.productDetails.options.options;
                builderSessionOne = optionsList.find(
                  (x) => x.label == "BuilderSessionId"
                );

                if (builderSessionOne) {
                  let sessionId = builderSessionOne.optionValue;
                  if (sessionId) {
                    await this.$store.dispatch("sessionOne/updateOne", {
                      sessionId: sessionId,
                      property: {
                        orderState: null,
                        quoteData: null,
                      },
                    });
                  }
                }
              }
            } catch (error) {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
            try {
              await this.$store.dispatch(
                "cartProduct/removeCartProduct",
                this.productId
               
              );
              googleAanalyticsEventRemoveCart(
                "remove_from_cart",
                this.productDetails
              );
              removeCookieByName(this.productId);
              this.snackbar = {
                message: "Product Removed Successfully",
                color: "red",
                show: true,
                timeout: 3000,
              };
            } catch (error) {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000,
              };
            }
            this.$emit("actionClose", this.snackbar);
          }
          
          if (this.$route.name == this.removeProdcutName) {
            this.$router
              .replace({
                name: this.removeProdcutName,
              })
              .catch((error) => {
                error;
              });
          }
        }
      } catch (error) {
        this.snackbar = {
          message: error.message,
          color: "error",
          show: true,
          timeout: 3000,
        };
      }
      this.confirmationDialog = false;
      this.removeProdcutName = "";
      this.confirmation = {
        confirmationMessage: "",
      };
      this.productId = "";
    },
    getBuilderSessionValueFromProduct(product) {
      let builderSessionOne = null;
      if (
        product &&
        product.options &&
        product.options.options &&
        product.options.options.length > 0
      ) {
        let optionsList = product.options.options;
        builderSessionOne = optionsList.find(
          (x) => x.label == "BuilderSessionId"
        );
      }
      return builderSessionOne;
    },
    async getPersonalizedPrice(product, qty, sku) {
      try {
        let productSummaryDetails = [];
        let builderSessionOne = this.getBuilderSessionValueFromProduct(product);
        let sessionId = null;
        if (builderSessionOne) {
          sessionId = builderSessionOne.value;
          if (!sessionId) {
            this.snackbar = {
              message: "Error while fetching price",
              color: "error",
              show: true,
              timeout: 3000,
            };
            throw "Session id is not present";
          }

          let resp = await this.$store.dispatch("sessionItemList/fetchList", {
            sessionId,
          });
          if (resp && resp.response) {
            for (let r = 0; r < resp.response.length; r++) {
              let item = resp.response[r];
              if (item.summaryData) {
                let parsedJson = JSON.parse(item.summaryData);
                productSummaryDetails.push(parsedJson);
              }
            }
          }
        }

        let partPrice = null;
        let subTotal = 0;
        let imagelibProduct = await doFetchProductTags(sku);
        let productPartsObjects = [];
        this.isPromotionApplied = false;
        for (let i = 0; i < productSummaryDetails.length; i++) {
          let productPart = productSummaryDetails[i];
          if (productPart.partSelected) {
            productPartsObjects[i] = {};
            productPartsObjects[i].partName = productPart.name;
            productPartsObjects[i].processes = productPart.processes;
            productPartsObjects[i].linerObject = productPart.linerObject;
             
          }
        }
        let response = await checkPromotion({
          sku: sku,
          quantity: qty,
          product: imagelibProduct ? imagelibProduct : {},
          productPartsObjects: productPartsObjects,
        });
        let priceAdjustmentType = null;
        let priceAdjustmentValue = null;
        if (response) {
          if (response.exclusiveRules.length !== response.exclusive.length)
            this.isPromotionApplied = false;
          else {
            if (response.appliedRules.length) {
              this.isPromotionApplied = true;
              let sortedPromotions = [];
              for (let i = 0; i < response.appliedRules.length; i++) {
                let promotion = response.appliedRules[i];
                if (promotion.price_adjustment == "fixed") {
                  sortedPromotions.unshift(promotion);
                } else {
                  sortedPromotions.push(promotion);
                }
              }
              //fixed is on top
              for (let r = 0; r < sortedPromotions.length; r++) {
                let sortedPromotion = sortedPromotions[r];
                if (sortedPromotion.price_adjustment == "fixed") {
                  subTotal = sortedPromotion.price_adjustment_value;
                } else if (sortedPromotion.price_adjustment == "bump_amount") {
                  subTotal += sortedPromotion.price_adjustment_value;
                } else if (
                  sortedPromotion.price_adjustment == "discount_percentage"
                ) {
                  priceAdjustmentType = "discount_percentage";
                  priceAdjustmentValue = sortedPromotion.price_adjustment_value;
                }
              }

              if (subTotal == 0 && !priceAdjustmentType)
                this.isPromotionApplied = false;
              
            } else {
              this.isPromotionApplied = false;
            }
          }
        }
        
        let pricerSubTotal = 0;
        let isPriceEngineFailed = false;
        let allErrors = [];
        

        for (let i = 0; i < productSummaryDetails.length; i++) {
          isPriceEngineFailed = false;
          
          let part = productSummaryDetails[i];

          // this condition part.currentPartSurfaceType === 'surface' is because, we already merged alternate elements in main part otherwise it will double current part price
          if (part.partSelected && part.currentPartSurfaceType === "surface") {
            try {
              let options = {
                pricingDateTime: Date.now(),
                fallbackToOMS: false,
                compareToOMS: false,
                audit: [],
              };
              let resp = null;
              if (part && part.partStructure?.elements) {
                resp = await getPartPricByStructure({
                  pricer: "cloud",
                  qty: qty,
                  sessionItem: part,
                  partStructure: part.partStructure,
                  options,
                });
              } else {
                part.orderObject.Details[0].Quantity = qty;
                part.orderObject.Details[0].Sublines.forEach((subline) => {
                  subline.Quantity = qty;
                });
                let newObj = JSON.parse(JSON.stringify(part.orderObject));
                resp = await getPartPricByStructure({
                  orderPriceObject: newObj,
                  sessionItem: part,
                  pricer: "cloud",
                  options,
                });
              }

              part.partPrice = 0;
              if (resp.skuBasedError && resp.skuBasedError.length) {
                allErrors.push(...resp.skuBasedError);
                this.saveIncident({
                  errorObject: {
                    stack: {
                      error: resp.skuBasedError.toString(),
                    },
                  },
                  title: "Personalize api pricing error",
                  description:
                    "Price api error for poduct sku " +
                    sku +
                    " and part name " +
                    part.name +
                    " with quantity " +
                    qty,
                  tags: ["price_api_error"],
                  sessionId,
                  toContinueSession: true,
                });
              } else if (resp.newPrice) {
                part.partPrice = resp.newPrice;
              } else if (options.fallbackToOMS && !resp.newPrice) {
                part.partPrice = resp.oldPrice;
              }
              pricerSubTotal += part.partPrice;
            } catch (error) {
              console.log(error);
              this.$snotify.error("Error while fetching price", {
                position: "rightTop",
                showProgressBar: false,
              });
            }
            

            // updating summary json part price for current item
            if (part.orderObject && part.orderObject.Details[0]) {
              part.orderObject.Details[0].partPrice = part.partPrice;
            }

            part.quantity = qty;
            try {
              await this.$store.dispatch("sessionItemOne/updateItemOrderData", {
                sessionId: sessionId,
                productPartId: part.id,
                surfaceType: part.currentPartSurfaceType,
                surfaceId:
                  part.currentPartSurfaceType === "surface"
                    ? part.surfaceId
                    : part.alternateSurfaceId,
                property: {
                  summaryData: JSON.stringify(part),
                },
              });
            } catch (err) {
              throw err;
            }
             
          }
        }
        if (allErrors.length) {
          allErrors = [...new Set(allErrors)];
          for (let e = 0; e < allErrors.length; e++) {
            this.$snotify.error(allErrors[e], {
              position: "rightTop",
              showProgressBar: false,
            });
          }
        }
        // }

        if (!this.isPromotionApplied) subTotal = pricerSubTotal;
        else if (
          priceAdjustmentType &&
          priceAdjustmentType == "discount_percentage"
        ) {
          if (Number(priceAdjustmentValue)) {
            subTotal =
              pricerSubTotal * (1 - Number(priceAdjustmentValue) / 100);

            
          }
        }

         
        return {
          subTotal: subTotal,
          personalizeDetails: null,
          sessionId: sessionId,
        };
      } catch (error) {
        console.log("error", error);
      }
    },
    async saveIncident({ errorObject, title, description, tags, sessionId }) {
      try {
        await this.$store.dispatch("incidentOne/setOne");
        let userAgent = navigator;

        let currentdate = await isoStringToNormalDate();
        let newErr = null;
        if (errorObject && errorObject.stack && errorObject.stack.new_price) {
          newErr = JSON.stringify(errorObject);
        } else {
          newErr = JSON.stringify(errorObject.toLocaleString());
        }

        let incidentObj = {
          title: title,
          level: "major",
          module: "storefront",
          description: description,
          tags: tags && tags.length ? tags : [],
          recorded_at: currentdate,
          metadata: {
            currentSessionId: sessionId,
            errorStack: JSON.stringify(errorObject.stack),
            error: newErr,
            platform: userAgent.platform,
            appVersion: userAgent.appVersion,
            appCodeName: userAgent.appCodeName,
          },
        };
        let savedIncidentResp = await this.$store.dispatch("incidentOne/save", {
          one: incidentObj,
        });
        console.log("savedIncidentResp", savedIncidentResp);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateToCartItem(
      ccid,
      sku,
      qty,
      itemId,
      routeName,
      price,
      personalizeDetails,
      isUser,
      builderVersion,
      sessionId
    ) {
      try {
        let resp = null;
        if (isUser) {
          resp = await this.$store.dispatch("userCartProduct/updateToCart", {
            ccid: ccid,
            sku: sku,
            qty: qty,
            itemId: itemId,
            routeName: routeName,
            price: price,
            personalizeDetails: personalizeDetails,
          });
        } else {
          resp = await this.$store.dispatch("cartProduct/updateToCart", {
            ccid: ccid,
            sku: sku,
            qty: qty,
            itemId: itemId,
            routeName: routeName,
            price: price,
            personalizeDetails: personalizeDetails,
          });
        }

        if (resp && builderVersion == "2.0") {
          let sessionOneDetails = await this.$store.dispatch(
            "sessionOne/fetchOne",
            { sessionId }
          );

          if (sessionOneDetails && sessionOneDetails.response)
            sessionOneDetails = sessionOneDetails.response[0];

          await this.$store.dispatch("sessionOne/updateOne", {
            sessionId: sessionId,
            property: {
              productData: {
                ...sessionOneDetails.productData,
                quantity: qty,
                productSubTotal: price,
              },
              quoteData: {
                ...sessionOneDetails.quoteData,
                qty,
                price: resp.price, // single note price
                productSubTotal: price,
              },
            },
          });
        }
        this.snackbar = {
          message: "Product Updated Successfully",
          color: "success",
          show: true,
          timeout: 3000,
        };
      } catch (error) {
        this.snackbar = {
          message: error.message,
          color: "error",
          show: true,
          timeout: 3000,
        };
      }
      this.$emit("actionClose", this.snackbar);
    },
  },
 
};
</script>
<style lang="scss">
.cartproductlist-select-button .v-field.v-field:hover {
  color: #2850da !important;
}
.cartproductlist-select-button .v-field .v-select--active-menu {
  border: 2px solid red !important;
}
.cartproductlist-select-button .v-field .v-field-focused {
  border: 2px solid red !important;
}
.cartproductlist-expansion .v-expansion-panel-content__wrap {
  padding: 0 0px 0px !important;
}
.prod-view-dialog {
  min-height: calc(100vh - 20px); // to adjust you can change 180px
   
}
.active-panel-edited .v-expansion-panel-title__overlay {
  background-color: transparent !important;
}
.myFlex {
  display: flex !important;
}
.myFlex,
.v-field__outline__start {
  border-radius: 0px !important;
}
.myFlex,
.v-field__outline__end {
  border-radius: 0px !important;
}
.border-left-none {
  border-left: none !important;
}
</style>
<style src="./CartProductList.scss" lang="scss" scoped />
