  <template>
  <div>
    <v-app-bar     
      height="56"
      :absolute="!isScrolled"
      :app="isScrolled"
      elevation="0"
      color="#7AB7DA"
    >
      <v-row      
        class="text-center regular-font herocontainer-min-width-650 mx-sm-auto mx-md-auto mx-lg-auto  "
        align="center"
        justify="center"
        :class="closeBanner()"
        v-html="getHeaderPromoBanner()"
      ></v-row>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  name: "HeaderPromoBanner",
  props: ["headerPromoBanner"],
  components: {},
  data: () => ({}),

  computed: {
    innerWidth() {
      return window.innerWidth;
    },
    isScrolled: function () {
      if (!this.heroData) return true;
      return this.offsetTop > 30 ? true : false;
    },
  },
  methods: {
    closeBanner() {
      this.$emit("closeHeroBanner");
    },
    getHeaderPromoBanner() {
      let path = this.headerPromoBanner.content;
      let html = path.replace(/<br>/g, "");
      let div = document.createElement("div");
      div.innerHTML = html;
      return div.innerHTML;
    },
  },
  created() {},
};
</script>
<style src="./HeaderPromoBanner.scss" lang="scss" />
 