<template>
  <div>
    
    <v-navigation-drawer
      v-model="cartDrawer"
      :width="!isDesktop ? defaultWidth : productListWidth"
      color="#ffffff"
      v-resize="resizeCart"
      v-click-outside="outside"
      class=""
      :class="this.$route.name == 'HomePage' ? '  headerpage-cart-drawer-top-56' : 'headerpage-cart-drawer'"
      location="right"
    >
      <v-row class="px-4 py-2" @click="stopPropogation">
        <v-col cols="7">
          <h2 class="black-text mt-3">Shopping Cart</h2>
        </v-col>
        <v-col cols="5" justify="center">
          <v-hover v-slot="{ isHovering, props }">
            <v-btn
              class="float-right mt-2"
              :variant="!isHovering ? 'flat' : 'outlined'"
              :color="isHovering ? 'white' : 'primary'"
              :class="
                isHovering ? 'text-primary header-closedrawer-button' : 'text-white'
              "
              depressed
              @click.stop="closeCartDrawer(false)"
              v-bind="props"
              :rounded="0"
              size="large"
            >
              CLOSE
            
              <v-icon :icon="mdiClose"  :class="isHovering ? 'text-primary' : 'text-white'"
                class="h3"/>
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
      <v-row v-show="cartProductsProgress || inProgress">
        <v-col class="my-12" align="center" justify="center">
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
      <div
        v-show="!cartProductsProgress && !inProgress"
        @click="stopPropogation"
      >
        <div v-show="cartProductsItem.length == 0" ref="defaultMsgDiv">
          <v-row>
            <v-col class="text-center pt-8 pb-16">
              <p class="black--text pb-0 mb-0 font-weight-medium">
                You have no items in your shopping cart.
              </p>
            </v-col>
          </v-row>
        </div>
        <div v-show="cartProductsItem.length !== 0">
          <div ref="checkoutButtonDiv">
            <v-row class="px-4 mt-2" v-if="stockFlag">
              <v-col cols="7">
                <p :class="isScrolled ? '' : 'text-black'">
                  {{ cartProductsItem.length }} Items
                </p>
              </v-col>
              <v-col cols="5" align="right">
                <p class="text-black mb-0">Cart Subtotal</p>
                <h2 :class="isScrolled ? '' : 'text-black'">
                  ${{ priceFormatter(cartProductTotalAmt.subtotal) }}
                </h2>
              </v-col>
            </v-row>
            <v-row class="px-4" v-if="stockFlag">
              <v-col cols="12">
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn
                    :rounded="0"
                    size="large"
                    block
                    :variant="!isHovering ? 'flat' : 'outlined'"
                    class="text-uppercase ls-0 body mt-5 pb-1"
                    :class="!isHovering ? ' text-white' : 'text-primary'"
                    @click="goToCheckout()"
                    v-bind="props"
                    color="primary"
                    >GO TO CHECKOUT</v-btn
                  >
                </v-hover>
              </v-col>
            </v-row>
          </div>
          <v-list
            class="headerpage-cart-list mb-6"
            :height="productListHeight()"
          >
            <v-list-item>
              <v-col class="overflow-y-scroll">
                <CartProductList
                  @actionClose="showSnackBarMessage"
                  :checkOut="chekoutFlag"
                  :cartProductsProgress="cartProductsProgress"
                  @actionEditMessage="showMessageOnPage"
                  @stockFlag="stockFlagHideShow"
                />
              </v-col>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import CartProductList from "@/components/crane/cart-product/CartProductList.vue";
import { priceFormatter } from "@/services/util.service";
import { mdiClose } from "@mdi/js";
export default {
  name: "NavigationDrawerComponent",
  components: {
    CartProductList,
  },
  props: [],
  data: () => ({
    mdiClose,
    priceFormatter: priceFormatter,
    defaultWidth: 400,
    chekoutFlag: true,
    stockFlag: true,
  }),
  computed: {
    isDesktop: function () {
      return this.$vuetify.display.xsOnly ? true : false;
    },
    productListWidth() {
      return window.innerHeight;
    },
    defaultHeight: function () {
      if (this.isDesktop) {
        return window.innerHeight;
      } else {
        return "200";
      }
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    cartDrawer: {
      get() {
        if (this.userAuth) {
          return this.$store.state.userCartProduct.cartDrawer;
        } else {
          return this.$store.state.cartProduct.cartDrawer;
        }
      },
      set() {},
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    inProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
    cartTotalAmtProgress() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartTotalAmtProgress;
      } else {
        return this.$store.state.cartProduct.cartTotalAmtProgress;
      }
    },
    cartProductsItem() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
    cartProductTotalAmt() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.productTotalAmt;
      } else {
        return this.$store.state.cartProduct.productTotalAmt;
      }
    },
  },
  watch: {
    cartProductsItem() {
      this.stockFlagHideShow();
    },
  },
  methods: {
    stockFlagHideShow() {
      if (this.cartProductsItem) {
        let errorCount = 0;
        let i;
        for (i = 0; i < this.cartProductsItem.length; i++) {
          if (
            (!this.cartProductsItem[i].stockDetails.isInStock ||
              this.cartProductsItem[i].stockDetails.qty <
                this.cartProductsItem[i].qty) &&
            this.cartProductsItem[i].stockDetails.manageStock &&
            this.cartProductsItem[i].stockDetails.backorders == 0
          ) {
            errorCount++;
          }
        }
        if (errorCount) {
          this.stockFlag = false;
        } else {
          this.stockFlag = true;
        }
      }
    },
    showMessageOnPage() {
      this.$emit("actionPageMessage");
    },
    goToCheckout() {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");
      if (isGateModulePage) {
        window.open("/checkout", "_self");
      } else {
        this.$router.push({
          name: "CheckoutPage",
          params: {},
        });
      }
    },
    isScrolled: function () {
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");
      if (isGateModulePage) {
        return this.offsetTop > 30 ? true : false;
      }
      if (!this.heroData) return true;

      return this.offsetTop > 30 ? true : false;
    },
    showSnackBarMessage(obj) {
      this.$emit("actionShowMessage", obj);
    },
    outside() { 
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
    },
    stopPropogation(event) {
      event.stopPropagation();
    },
    closeCartDrawer(flag) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", flag);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", flag);
      }
    },
    resizeCart() {
      return this.sideBarHeight() && this.productListHeight();
    },
    productListHeight() {
      return window.innerHeight - 200;
     
    },
    sideBarHeight() {
      return window.innerHeight + 156;
    },
    pageName() {
      return this.$route.name;
    },

    goToLogout() {
      
      this.$store.dispatch("auth/logout"); 
    },
    goToLogin() {
      window.open("/gate/sign-in", "_self");
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vNode) {
        
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
     
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
       
        document.addEventListener("click", handler);
      },
      unbind: function (el, binding) {
        console.warn(binding);
        
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  created() {
    this.stockFlagHideShow();
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.$store.dispatch("cartProduct/cartDrawer", false);
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      }
    });
  },
};
</script>

<style src="./NavigationDrawerComponent.scss" lang="scss" scopped/>
