import { priceProduct } from "crane-pricer";

export const getPartPricByStructure = async ({ pricer, qty, sessionItem, partStructure, options, orderPriceObject }) => {
  try {
    let skuBasedError = []
    let pricerResp = null

    if(orderPriceObject){
      pricerResp = await priceProduct({ pricer, orderPriceObject, options });
    } else {

      let quantity = qty || 50;
      let productConfiguration = {
        configuredStructure: partStructure,
        qty: quantity,
      };
      pricerResp = await priceProduct({ pricer, productConfiguration, options });
    }

    if (pricerResp.status === "success") {
      return {
        oldPrice: pricerResp.omsPrice,
        newPrice: pricerResp.cloudPrice,
      };
    } else {
      if (pricerResp.error.includes("properties of undefined")) {
        console.log(pricerResp.error)

        let errMsg = `The current part "${sessionItem.partName || sessionItem.name || ''}" have broken svg, please contact to support team`;
        if (!skuBasedError.includes(errMsg)) skuBasedError.push(errMsg);
        return {
          skuBasedError,
          oldPrice: 0,
          newPrice: 0
        };
      } else if (!skuBasedError.includes(pricerResp.error)) {
        skuBasedError.push(pricerResp.error);
        return {
          skuBasedError,
          oldPrice: 0,
          newPrice: 0
        };
      }
    }
    return {
      skuBasedError,
      oldPrice: 0,
      newPrice: 0
    };
  } catch (e) {
    console.log("Error in sku ", e);
    throw e
  }
};