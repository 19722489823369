<template>
  <div v-resize="checkActiveCategory">
    <HeaderPromoBanner
      v-if="
        pageName() == 'HomePage' &&
        heroBanner &&
        headerPromoBanner &&
        headerPromoBanner.active
      "
      @closeHeroBanner="closeBanner"
      :headerPromoBanner="headerPromoBanner"
    />
    <v-app-bar
      v-if="heroData && newUser"
      flat
      :class="
        pageName() == 'HomePage' &&
        heroBanner &&
        headerPromoBanner &&
        headerPromoBanner.active
          ? 'header-top-margin transparent-background'
          : 'transparent-background'
      "
      absolute
      :color="'rgba(0,0,0,0.0)'"
      elevation="0"
      fixed
      class="d-flex"
    >
      <template v-slot:append>
        <v-app-bar-nav-icon class="d-md-none"></v-app-bar-nav-icon>

        <v-img
          v-if="pageName() == 'CategoryPage'"
          :src="craneLogoBlue"
          alt="Crane Blue Logo"
          max-height="44"
          max-width="146"
          contain
          @click="goToHomePage()"
          class="mt-md-11 mb-5 cursor-pointer"
        ></v-img>
        <v-img
          v-else
          :src="craneLogoWhite"
          max-height="44"
          max-width="100"
          @click="goToHomePage()"
          class="craneImage mt-md-9 mb-5 cursor-pointer"
          alt="Crane Blue Logo"
        ></v-img>
      
        <v-menu
          offset-y
          down
          allow-overflow
          v-if="userAuth"
          :disabled="isScrolled"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              icon
              v-bind="props"
              :class="textColor"
              class="d-none d-md-block header-buttons"
              :max-width="$vuetify.display.mdOnly ? '35px' : ''"
              variant="plain"
            >
              <div class="text-uppercase">Hi {{ userName }}</div>
            </v-btn>
          </template>
          <v-card elevation="0" tile>
            <v-list dense flat>
              <v-list-item>
                <v-list-item-title
                  @click="goToDashboard()"
                  class="cursor-pointer"
                  >My Account</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="goToLogout()" class="cursor-pointer"
                  >Logout</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-hover v-slot:default="{ isHovering, props }" v-if="!userAuth">
          <v-btn
            icon
            @click="goToLogin()"
            class="d-none d-md-block header-buttons"
            :max-width="$vuetify.display.mdOnly ? '35px' : ''"
          >
         
            <v-icon
              :icon="mdiAccountCircleOutline"
              :class="isHovering ? 'text-primary' : 'text-white'"
              v-bind="props"
            />
          </v-btn>
        </v-hover>

        <v-hover v-slot:default="{ isHovering, props }">
          <v-btn
            icon
            v-bind="props"
            :class="
              isHovering ? 'text-primary' : textColor ? textColor : 'text-white'
            "
            @click="showSearchInput = true"
            class="d-none d-md-block header-buttons"
            :max-width="$vuetify.display.mdOnly ? '35px' : ''"
          >
         
            <v-icon
              :icon="mdiMagnify"
              v-if="pageName() == 'CategoryPage'"
              :class="isHovering ? 'header-category-icon' : 'text-primary'"
            />
 
            <v-icon
              :icon="mdiMagnify"
              v-else
              :class="isHovering ? 'text-primary' : 'text-white'"
            />
          </v-btn>
        </v-hover>

        <v-hover v-slot:default="{ isHovering, props }">
          <v-btn
            v-bind="props"
            icon
            :class="isHovering ? 'text-primary' : 'text-white'"
            @click.stop="showCartDrawer(true)"
            :max-width="$vuetify.display.mdOnly ? '35px' : ''"
          >
        
            <v-icon
              :icon="mdiCartOutline"
              :class="pageName() == 'CategoryPage' ? 'text-white' : ''"
            />

            <span v-if="cartProductsProgress || inProgress">
              <v-progress-circular
                :size="20"
                color="primary"
                :width="2"
                indeterminate
              ></v-progress-circular>
            </span>
            <span v-else>
              <v-badge
                class="mb-9 ml-2 badge-text-white"
                v-show="cartProductsItem.length"
                color="pink"
                :content="cartProductsItem.length"
              ></v-badge>
            </span>
          </v-btn>
        </v-hover>
      </template>

      <template v-slot:extension>
        <v-spacer></v-spacer>
        <div
          v-for="(categories, index) in categoryList.childrenData"
          v-bind:key="index"
        >
          <v-toolbar-items
            class="text-white d-none d-md-flex"
            v-if="index === 0"
          >
            <v-menu
              open-on-hover
              down
              offset-y
              allow-overflow
              content-class="pt-4 header-menu-content"
              v-for="(category, i) in categories.childrenData"
              v-bind:key="i"
            >
              <template v-slot:activator="{ props }">
              
                <div
                  v-if="
                    category.name !== 'Baby' &&
                    category.name !== 'Gifts' &&
                    category.isActive
                  "
                  text
                  class="mt-5 cursor-pointer text-uppercase body my-auto pa-0 px-sm-2 ls-n01 px-lg-4 header-category-text"
                  v-bind="props"
                  :class="
                    category.id == activeCategoryMenu
                      ? 'header-category-text-active'
                      : categoryId
                      ? 'header-category-page-text'
                      : 'text-white'
                  "
                  @click="categoryProducts(category.id, category)"
                >
                  <span class="text-white myText">{{ category.name }}</span>
                </div>
              </template>
              <v-col v-if="category.childrenData != ''">
                <SubMenu
                  :subCategoryList="category"
                  @action="inactiveCategoryMenu"
                  :mainMenu="category.id"
                  :subMenuItem="subMenuItem"
                  @onActionScrollSub="onActionScroll"
                />
              </v-col>
            </v-menu>
          </v-toolbar-items>
        </div>
        <v-spacer></v-spacer>
      </template>
    </v-app-bar>
    <v-app-bar
      height="56"
      :absolute="!isScrolled"
      :app="isScrolled"
      :color="
        isScrolled || hover ? 'header-background-white' : 'rgba(0,0,0,0.0)'
      "
      elevation="0"
      v-else-if="pageName() == 'CheckoutPage'"
    >
      <v-spacer></v-spacer>
      <v-img
        v-if="!isScrolled"
        :src="CraneLogoWhite"
        max-width="100"
        @click="goToHomePage()"
        class="cursor-pointer"
        contain
        aspect-ratio="1"
        alt="Crane White Logo"
      ></v-img>
      <v-img
        v-if="isScrolled"
        :src="craneLogoBlue"
        max-width="100"
        class="cursor-pointer"
        @click="goToHomePage()"
        contain
        aspect-ratio="1"
        alt="Crane White Logo"
      ></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-hover v-else v-slot:default="{ isHovering, props }">
      <v-app-bar
        height="56"
        :class="
          pageName() == 'HomePage' &&
          heroBanner &&
          headerPromoBanner &&
          headerPromoBanner.active
            ? 'header-top-margin'
            : ''
        "
        :absolute="!isScrolled"
        :app="isScrolled"
        :color="
          isScrolled || isHovering
            ? 'header-background-white'
            : 'rgba(0,0,0,0.0)'
        "
        v-bind="props"
        elevation="0"
        class="px-4"
      >
        <v-col class="d-flex justify-start pa-0">
          <v-app-bar-nav-icon
            @click.stop="menuAction()"
            :class="textColor"
            class="d-md-none header-menu-button ml-n3 pl-1"
            :color="
              isHovering && !isScrolled
                ? 'primary'
                : isScrolled
                ? 'primary'
                : ''
            "
          ></v-app-bar-nav-icon>
          <v-img
            v-if="!isScrolled && !isHovering"
            :src="craneLogoWhite"
            max-width="100"
            @click="goToHomePage()"
            class="cursor-pointer d-none d-md-flex"
            contain
            aspect-ratio="1"
            alt="Crane White Logo"
          ></v-img>
          <v-img
            v-else-if="(isScrolled && !isHovering) || isHovering"
            :src="craneLogoBlue"
            max-width="100"
            class="cursor-pointer d-none d-md-flex"
            @click="goToHomePage()"
            contain
            aspect-ratio="1"
            alt="Crane Blue Logo"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center">
          <v-img
            v-if="!isScrolled && !isHovering"
            :src="craneLogoWhite"
            max-width="100"
            @click="goToHomePage()"
            class="cursor-pointer d-md-none"
            contain
            aspect-ratio="1"
            alt="Crane White Logo"
          ></v-img>
          <v-img
            v-else-if="(isScrolled && !isHovering) || isHovering"
            :src="craneLogoBlue"
            max-width="100"
            class="cursor-pointer d-md-none"
            @click="goToHomePage()"
            contain
            aspect-ratio="1"
            alt="Crane Blue Logo"
          ></v-img>
          <div
            v-for="(categories, index) in categoryList.childrenData"
            v-bind:key="index"
          >
            <v-toolbar-items
              class="text-white d-none d-md-flex"
              v-if="index === 0"
            >
              <v-menu
                open-on-hover
                down
                offset-y
                allow-overflow
                content-class="pt-4 header-menu-content"
                v-for="(category, index) in categories.childrenData"
                v-bind:key="index"
                v-show="category.isActive"
              >
                <template v-slot:activator="{ props }">
                  <div
                    v-if="
                      category.name !== 'Baby' &&
                      category.name !== 'Gifts' &&
                      category.isActive
                    "
                    text
                    class="cursor-pointer text-uppercase my-auto body pa-0 d-inline-block px-sm-2 ls-n01 px-lg-4 menu-nav-link custom-menu"
                    v-bind="props"
                    :class="
                      isScrolled || isHovering
                        ? category.id == activeCategoryMenu && categoryId
                          ? 'header-category-text-active'
                          : 'header-category-text'
                        : category.id == activeCategoryMenu && categoryId
                        ? 'header-category-text-active'
                        : textColor
                    "
                    @click="categoryProducts(category.id, category)"
                  >
                    {{ category.name }}
                  </div>
                </template>
                <v-col v-if="category.childrenData != ''">
                  <SubMenu
                    :subCategoryList="category"
                    @action="inactiveCategoryMenu"
                    :mainMenu="category.id"
                    :subMenuItem="subMenuItem"
                    @onActionScrollSub="onActionScroll"
                  />
                </v-col>
              </v-menu>
            </v-toolbar-items>
          </div>
        </v-col>
        <v-col class="d-flex justify-end pr-0">
          <v-toolbar-items>
            <v-menu v-if="userAuth" v-model="showMenu">
              <template v-slot:activator="{ props }">
                <v-btn
                  icon
                  v-bind="props"
                  :class="textColor"
                  :color="
                    isHovering && !isScrolled
                      ? 'primary'
                      : isScrolled
                      ? 'primary'
                      : ''
                  "
                  @click="showMenu = true"
                  class="d-none d-md-block header-buttons mr-6"
                  :max-width="$vuetify.display.mdOnly ? '35px' : ''"
                >
                  <div class="text-uppercase">Hi {{ userName }}</div>
                </v-btn>
              </template>
              <v-card elevation="0" class="mt-3 custom-menu-dropdown">
                <v-list density="compact" class="">
                  <v-list-item class="">
                    <v-list-item-title
                      @click="goToDashboard()"
                      class="cursor-pointer font-weight-medium text-caption"
                      >My Account</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item class="">
                    <v-list-item-title
                      @click="goToLogout()"
                      class="cursor-pointer font-weight-medium text-caption"
                      >Logout</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-btn
              v-if="!userAuth"
              icon
              :color="
                isHovering && !isScrolled
                  ? 'primary'
                  : isScrolled
                  ? 'primary'
                  : ''
              "
              @click="goToLogin()"
              :class="textColor"
              class="d-none d-md-block header-buttons"
              :max-width="$vuetify.display.mdOnly ? '35px' : ''"
            >
              <!-- <v-icon class="header-action-icons"
                >mdi-account-circle-outline</v-icon
              > -->
              <v-icon :icon="mdiAccountCircleOutline" />
            </v-btn>

            <v-btn
              icon
              :color="
                isHovering && !isScrolled
                  ? 'primary'
                  : isScrolled
                  ? 'primary'
                  : ''
              "
              @click="showSearchInput = true"
              :class="textColor"
              class="d-none d-md-block header-buttons"
              :max-width="$vuetify.display.mdOnly ? '35px' : ''"
            >
              <!-- <v-icon class="header-action-icons">mdi-magnify</v-icon> -->
              <v-icon :icon="mdiMagnify" />
            </v-btn>

            <!--<v-btn
            v-show="pageName() !== 'CartProducts'"
            icon
            :class="textColor"
            class="header-buttons"
            @click="categoryProducts('10')"
            :max-width="this.$vuetify.display.mdOnly? '35px':''"
          >
            <v-icon>mdi-shopping-outline</v-icon>
            </v-btn>-->
            <div class="test">
              <v-btn
                v-show="pageName() !== 'CartProducts'"
                icon
                :class="textColor"
                :color="
                  isHovering && !isScrolled
                    ? 'primary'
                    : isScrolled
                    ? 'primary'
                    : ''
                "
                class="header-buttons mr-n2"
                @click.stop="showCartDrawer(true)"
                :max-width="$vuetify.display.mdOnly ? '35px' : ''"
              >
                <!-- <v-icon class="header-action-icons">mdi-cart-outline</v-icon> -->
                <v-icon :icon="mdiCartOutline" />

                <span v-if="cartProductsProgress || inProgress">
                  <v-progress-circular
                    :size="20"
                    color="primary"
                    :width="2"
                    indeterminate
                  ></v-progress-circular>
                </span>
                <span v-else>
                  <v-badge
                    class="mb-4 badge-text-white"
                    color="pink"
                    v-show="cartProductsItem.length"
                    :content="cartProductsItem.length"
                  ></v-badge>
                </span>
              </v-btn>
            </div>
          </v-toolbar-items>
        </v-col>
      </v-app-bar>
    </v-hover> 
    <v-navigation-drawer
      v-model="showSearchInput"
      v-if="showSearchInput"
      temporary
      :scrim="false"
      color="#ffffff"
      :width="searchInputWidth"
      :location="showSearchInput ? 'right' : ''"
      :class="[
        showSearchInput
          ? 'headerpage-search-drawer-block'
          : 'headerpage-search-drawer-none',
        this.$route.name == 'HomePage' ? 'top-56' : 'top-0',
      ]"
    >
    
      <v-text-field
        v-model="search"
        autofocus
        clearable
        single-line
        placeholder="Search"
        solo
        type="text"
        class="clickable header-search-field"
        @blur="showSearchInput = false"
        v-on:keyup.enter="GotoPage"
        variant="compact"
        color="primary"
        :clear-icon="mdiClose"
      >
        <template v-slot:append-inner>
        
          <v-icon @click="GotoPage()" :icon="mdiMagnify" />
        </template>
      </v-text-field>
    </v-navigation-drawer>
    <NavigationDrawerComponent
      v-if="pageName() !== 'CartProducts'"
      @actionShowMessage="showMessage"
      @actionPageMessage="pageMessage"
    ></NavigationDrawerComponent>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
  </div>
</template>
<script> 
import SubMenu from "@/components/layout/sub-menu/SubMenu.vue";
import NavigationDrawerComponent from "@/components/layout/header/NavigationDrawerComponent.vue";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import { doFetchCatelogProducts } from "@/services/product.service";
import HeaderPromoBanner from "@/components/layout/header/HeaderPromoBanner.vue";
import {
  customAttributeValueNew,
  googleAanalyticsEventCategory,
  getCategoryOne,
  goToCategoryPageRouting,
  snakeToCamel,
} from "@/services/util.service";
import craneLogoBlue from "@/assets/Crane_Logo_blue-c7337d8c.webp";
import craneLogoWhite from "@/assets/Crane_Logo_white-8ca28087.webp";
import {
  mdiAccountCircleOutline,
  mdiMagnify,
  mdiCartOutline,
  mdiClose,
} from "@mdi/js";

export default {
  name: "Header",
  components: {
    SubMenu,
    NavigationDrawerComponent,
    SnackbarComponent,
    HeaderPromoBanner,
  },
  props: ["offsetTop", "heroData"],
  data: () => ({
    mdiAccountCircleOutline,
    mdiMagnify,
    mdiCartOutline,
    mdiClose,
    blockId: "30",
    heroBanner: true, 
    activeCategoryMenu: "",
    showMenu: false,
    pageNo: 1,
    defaultWidth: 400,
    pageSize: 9,
    sortSelect: { label: "Position", value: "position" },
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
    search: null,
    number: "",
    searchResultList: [],
    searchInProgress: false,
    lookupCatelog: null,
    showSearchInput: false,
    craneLogoBlue: craneLogoBlue,
    craneLogoWhite: craneLogoWhite,
    showMenu: false,
  }),
  watch: {
    lookupCatelog: {
      handler: function (newVal) {
        clearTimeout(this.lookupCatelogProductTimeoutId);
        this.lookupCatelogProductTimeoutId = setTimeout(async () => {
          try {
            if (!newVal) this.searchResultList = [];
            else {
              this.searchInProgress = true;
              let results = await doFetchCatelogProducts(newVal);
              if (results != null) {
                this.searchResultList = snakeToCamel(results);
              } else {
                this.searchResultList = [];
              }
              this.searchInProgress = false;
            }
          } catch (error) {
            this.searchResultList = [];
          }
        }, 500);
      },
    },
    categoryId() {
      if (!this.categoryId) {
        localStorage.removeItem("cr_subCategoryItemId");
        localStorage.removeItem("cr_subCategoryId");
        localStorage.removeItem("cr_mainCategoryId");
      }
    },
  },

  computed: {
  
    snackbarFlag() {
      if (this.$store.state.userCartProduct.autoLogoutFlag) {
        this.showMessage({
          message: "Your session has expired please login again.",
          color: "error",
          show: true,
          timeout: 5000,
        });
      }
      return "";
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    inProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
    isDesktop: function () {
      return this.$vuetify.display.xsOnly ? true : false;
    },

    newUser() {
      if (this.$vuetify.display.smAndDown) return false;
      let userVisits = this.$store.state.layout.user.userVisits;
      if (userVisits && Number(userVisits) > 1) return false;
      else return true;
    },

    searchInputWidth() {
      return this.$vuetify.display.xs ? "100%" : "256";
    },
    isScrolled: function () {
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");
      if (isGateModulePage) {
        return this.offsetTop > 30 ? true : false;
      }
      if (!this.heroData || this.pageName() === "CategoryPage") return true;
      return this.offsetTop > 30 ? true : false;
    },
    textColor: function () {
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");
      if (isGateModulePage) {
        return "text-white";
      }
      if (!this.heroData) return "primary-bg";

      if (this.offsetTop > 30) return "primary-bg";
      else return "text-white";
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    cartProductsItem() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
 
    userName: function () {
      return this.$store.state.accountOne.one.firstname;
    },
    categoryList: function () {
      return this.$store.state.headerCategoryList.categoryList;
    },
    headerPromoBanner: function () {
      let cmsBlockContent = this.$store.state.layout.cmsBlockContent;

      if (cmsBlockContent.length) {
        let data = cmsBlockContent.filter((element) => {
          return element.identifier === "header-promo-banner";
        })[0];

        if (data) return data;
        else return null;
      }
      return null;
    },
    subMenuItem: {
      get: function () {
        return this.$store.state.headerCategoryList.categoryId;
      },
      set: function () {},
    },
    categoryId() {
      return this.$route.meta.id;
    },
    categoryDetailsList: function () {
      return this.$store.state.headerCategoryList.categoryDetails;
    },
  },
  methods: {
    pageMessage() {
      this.snackbar = {
        message: "Updated Product View",
        color: "success",
        show: true,
        timeout: 3000,
      };
    },


    inside: function (e) {
      console.log("clicked inside!", e);
    },
    onActionScroll() {
      this.$emit("actionScroll");
    },
    pageName() {
      return this.$route.name;
    },
    menuAction: function () {
      this.$emit("action", {
        showMenu: !this.showMenu,
        categoryList: this.categoryList,
        activeCategoryMenu: this.activeCategoryMenu,
        subMenuItem: this.subMenuItem,
      });
    },
    showCartDrawer(flag) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", flag);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", flag);
      }
    },

    showMessage(obj) {
      this.snackbar = {
        show: obj.show,
        message: obj.message,
        color: obj.color,
        timeout: obj.timeout,
      };
    },
    goToDashboard() {
      window.open("/me/account-dashboard", "_self");
    },
    goToLogin() {
      localStorage.removeItem("cr_mainCategoryId");
      localStorage.removeItem("cr_subCategoryItemId");
      window.open("/gate/sign-in", "_self");
    },
    goToLogout() {
      localStorage.removeItem("cr_checkOutTab");
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");
      if (isGateModulePage) {
        window.open("/logout-success", "_self");
      } else {
        this.$router.push({
          name: "LogOutSuccess",
        });
        setTimeout(() => {
       this.$emit("logout")
         }, 3000);

         
      }
    },
    goToHomePage() {
      window.open("/", "_self");
    },
    async inactiveCategoryMenu(data) {
      this.activeCategoryMenu = data.mainMenu;
      this.$store.dispatch(
        "headerCategoryList/setCategoryId",
        data.subMenuItem
      );
      localStorage.setItem("cr_mainCategoryId", data.mainMenu);
      localStorage.setItem("cr_subCategoryItemId", data.subMenuItem);
      localStorage.setItem("cr_subCategoryId", data.subMenu);
      if (data.subMenuItem) {
        if (this.categoryDetailsList) {
          let categoryOne = await getCategoryOne(
            this.categoryDetailsList,
            data.subMenuItem
          );
          if (categoryOne) {
            this.$store.dispatch("layout/setHeroContent", categoryOne);
            let path = this.getCustomAttributeValue(categoryOne.ca, "url_path");
            console.log(categoryOne.n, categoryOne);
            let pagePath = this.$route.path;
            let isGateModulePage = pagePath.includes("/gate/");
            if (isGateModulePage) {
              window.open(`/${path}`, "_self");
            } else {
              let vm = this;
              goToCategoryPageRouting(vm, categoryOne);
            }
          }
        }
      }
    },
    activeCategories() {
      if (this.categoryId) {
        let Category = localStorage.getItem("cr_mainCategoryId");
        let subCategory = localStorage.getItem("cr_subCategoryItemId");
        if (Category && subCategory) {
          this.activeCategoryMenu = Category;
          this.$store.dispatch("headerCategoryList/setCategoryId", subCategory);
        } else if (Category) {
          this.activeCategoryMenu = Category;
        } else {
          let subMenuItem = "";
          this.activeCategoryMenu = localStorage.getItem("cr_mainCategoryId");
          this.$store.dispatch("headerCategoryList/setCategoryId", subMenuItem);
        }
      } else {
        localStorage.removeItem("cr_mainCategoryId");
        localStorage.removeItem("cr_subCategoryItemId");
        localStorage.removeItem("cr_subCategoryId");
      }
    },
    checkActiveCategory() {
      let Category = localStorage.getItem("cr_mainCategoryId");
      let subCategory = localStorage.getItem("cr_subCategoryItemId");
      if (Category && subCategory) {
        this.activeCategoryMenu = Category;
        this.$store.dispatch("headerCategoryList/setCategoryId", subCategory);
      } else {
        let subMenuItem = "";
        this.activeCategoryMenu = localStorage.getItem("cr_mainCategoryId");
        this.$store.dispatch("headerCategoryList/setCategoryId", subMenuItem);
      }
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValueNew(customAttributes, attributeCode);
    },
    async categoryProducts(categoryId, categoryDetails) {
      if (this.categoryDetailsList) {
        let categoryOne = await getCategoryOne(
          this.categoryDetailsList,
          categoryId
        );
        if (categoryOne) {
          this.$store.dispatch("layout/setHeroContent", categoryOne);
          let path = this.getCustomAttributeValue(categoryOne.ca, "url_path");
          this.activeCategoryMenu = categoryId;
          console.log(categoryOne.n, categoryOne);
          googleAanalyticsEventCategory(
            "browse_category",
            "ecommerce",
            categoryDetails
          );
          localStorage.setItem("cr_mainCategoryId", categoryId);
          localStorage.removeItem("cr_subCategoryItemId");
          localStorage.removeItem("cr_subCategoryId");
          let subMenuItem = "";
          this.$store.dispatch("headerCategoryList/setCategoryId", subMenuItem);
          this.$emit("actionScroll");
          let pagePath = this.$route.path;
          let isGateModulePage = pagePath.includes("/gate/");
          if (isGateModulePage) {
            window.open(`/${path}`, "_self");
          } else {
            let vm = this;
            goToCategoryPageRouting(vm, categoryOne);
          }
          this.$store
            .dispatch("catalogBrowser/search", {
              fullCatalog: window.craneCatalog,
              keywords: "",
              filters: [{ label: "Category", prop: "ci", value: categoryId }],
              pageNumber: 1,
              pageSize: 9,
              sortBy: "position",
            })
            .catch((error) => {
              error;
            });
        }
      }
    },
    GotoPage() {
      if (this.search) {
     
        this.showSearchInput = false;
        this.searchResultList = [];
        let searchKeywords = this.search;
        let pagePath = this.$route.path;
        let isGateModulePage = pagePath.includes("/gate/");
        if (isGateModulePage) {
          window.open(
            `/search?pageNumber=1&pageSize=9&sortBy=relevance&q=${searchKeywords}&filter=W10=`,
            "_self"
          );
        } else {
          this.$router
            .push({
              name: "SearchPage",
              query: {
                pageNumber: 1,
                pageSize: 9,
                sortBy: "relevance",
                q: searchKeywords,
                filter: "W10=",
              },
            })
            .catch((error) => {
              error;
            });
          this.$store.dispatch("catalogBrowser/search", {
            fullCatalog: window.craneCatalog,
            keywords: searchKeywords,
            filters: [],
            pageNumber: 1,
            pageSize: 9,
            sortBy: "relevance",
          });
        }
        localStorage.removeItem("cr_subCategoryItemId");
        localStorage.removeItem("cr_subCategoryId");
        localStorage.removeItem("cr_mainCategoryId");
        this.search = null;
      }
    },
    closeBanner() {
      setTimeout(() => {
        this.heroBanner = true;
      }, 10000);
    },
  },
  created() {
    if (
      localStorage.getItem("cr_mainCategoryId") ||
      localStorage.getItem("cr_subCategoryItemId")
    ) {
      this.activeCategories();
    } else {
      localStorage.setItem("cr_mainCategoryId", this.categoryId);
      this.activeCategories();
    }
  },
};
</script>
<style src="./Header.scss" lang="scss" scopped/>